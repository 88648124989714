import PropTypes from "prop-types";
import { Image, classNames, XOutlineIcon } from "@gonoodle/gn-universe-ui";

function ChatBubble({ id, className, children, onDismiss }) {
  if (!children) {
    return null;
  }

  return (
    <div
      className={classNames("w-full xs:w-72", className)}
      role="dialog"
      tabIndex="-1"
    >
      <div id={id} className="bg-white rounded-2xl p-4">
        {onDismiss && (
          <button
            className="ml-auto translate-x-1/4 -translate-y-1/4 float-right"
            onClick={onDismiss}
          >
            <XOutlineIcon className="h-4 w-4" aria-hidden />
            <span className="sr-only">Dismiss message</span>
          </button>
        )}

        {children}
      </div>
      <svg
        className="ml-4 -mt-1"
        width="17"
        height="19"
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden
      >
        <path
          d="M5.37727 18.0781C6.17348 11.9754 8.49707 3.11657 16.9049 0.863703L1.8637 0.752876C-1.63049 7.40568 0.887627 14.1908 3.64948 18.5284C4.13623 19.2929 5.26003 18.9767 5.37727 18.0781Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default function ChampChatBubble({
  onDismiss,
  imageSources,
  message,
  children,
}) {
  return (
    <ChatBubble
      id="chat-bubble"
      className="absolute bottom-0 left-0 pb-2 px-6"
      onDismiss={onDismiss}
    >
      <p className="max-w-72" aria-live="polite">
        {message}
      </p>

      {imageSources && (
        <Image
          className="w-full brightness-[0.1] object-contain"
          sources={imageSources}
          alt=""
        />
      )}

      {children}
    </ChatBubble>
  );
}

ChampChatBubble.propTypes = {
  onDismiss: PropTypes.func,
  imageSources: Image.propTypes.sources,
  message: PropTypes.node.isRequired,
  children: PropTypes.node,
};

ChatBubble.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func,
};
