import React from "react";
import {
  Skeleton,
  Image,
  CallToActionLabel,
  BackgroundVideo,
  Pressable,
  AgeGatedPressable,
  ArrowLeftOutlineIcon,
  ArrowRightOutlineIcon,
  PlayOutlineIcon,
  DownloadOutlineIcon,
} from "@gonoodle/gn-universe-ui";
import {
  SECTIONS,
  HERO_UNIT_ITEM_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import useMeasure from "react-use-measure";

import {
  CONTENT_TYPES,
  URLS,
  IMPRESSION_TYPES,
  AD_PRODUCTS_TYPES,
} from "../../../../constants";
import { useDataLoader } from "../../hooks";
import { useLogEvent } from "../../../../contexts/Analytics";
import { getRoutePrefixByContentType } from "../../../../utils/route";
import Link from "../../../Link";
import {
  useUserCurriculumQuery,
  useTruncatedText,
  useImpression,
  useRouter,
} from "../../../../hooks";
import {
  requiresAgeGate,
  transformSuperNoodleMarketingUrlToCurriculum,
} from "../../../../utils/superNoodleMarketingUrl";

const getOriginalItemTitle = ({ contentType, content }) =>
  [CONTENT_TYPES.TAG, CONTENT_TYPES.CHANNEL, CONTENT_TYPES.TOPIC].includes(
    contentType,
  )
    ? content.name
    : content.title;

const swipePower = (offset, velocity) => Math.abs(offset) * velocity;
const swipeConfidenceThreshold = 7_000;

function PrimaryCard({
  title,
  media,
  sponsorLogo,
  callToAction,
  impressionsProps,
}) {
  const inViewRef = useImpression({
    properties: {
      ...impressionsProps,
      adProductType: AD_PRODUCTS_TYPES.VIDEO_THUMBNAIL,
      impressionType: IMPRESSION_TYPES.VIDEO_TILE,
    },
    enabled: !!impressionsProps,
  });

  return (
    <div
      ref={inViewRef}
      className="flex flex-col bg-purple h-full w-full p-1 rounded-lg"
    >
      <div className="aspect-w-16 aspect-h-9 relative overflow-hidden">
        <BackgroundVideo
          className="w-full h-auto object-contain rounded-t-lg"
          source={media.loopingVideo}
          fallbackImage={{
            sources: media.backgroundImage,
            alt: title,
          }}
        />

        {sponsorLogo && (
          <div className="absolute w-1/5 h-fit ml-auto mt-auto pb-2 pr-2">
            <Image
              className="w-full h-auto object-contain"
              alt="Sponsor logo"
              sources={sponsorLogo}
            />
          </div>
        )}
      </div>

      <div className="bg-purple flex flex-col px-3 pt-6 pb-5 space-y-4 md:flex-row md:justify-between md:items-center md:px-7 md:space-y-0 md:space-x-4">
        <span
          className="font-extrabold text-lg text-white line-clamp-2"
          style={{ textShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)` }}
        >
          {title}
        </span>

        <CallToActionLabel
          variant="light"
          size="md"
          className="min-w-fit max-w-fit"
        >
          {callToAction}
        </CallToActionLabel>
      </div>
    </div>
  );
}

function SecondaryCard({
  title,
  description,
  textTheme,
  image,
  impressionsProps,
}) {
  const textColor = textTheme === "light" ? "text-white" : "text-gray-900";
  const { containerRef, lineClamp } = useTruncatedText();
  const inViewRef = useImpression({
    properties: {
      ...impressionsProps,
      impressionType: IMPRESSION_TYPES.VIDEO_TILE,
      adProductType: AD_PRODUCTS_TYPES.VIDEO_THUMBNAIL,
    },
    enabled: !!impressionsProps,
  });

  return (
    <div
      ref={inViewRef}
      className="flex flex-row h-full space-x-3 lg:flex-col lg:space-x-0 lg:space-y-5"
    >
      <div className="rounded-lg overflow-hidden w-[37%] lg:w-full">
        <div className="aspect-w-16 aspect-h-9 overflow-hidden">
          <Image
            className="w-full h-auto object-contain"
            sources={image}
            alt={title}
          />
        </div>
      </div>

      <div className={twMerge("flex flex-col flex-1 pr-1", textColor)}>
        <span className="text-sm font-bold line-clamp-2 overflow-hidden lg:text-md">
          {title}
        </span>

        <div
          ref={containerRef}
          className="relative hidden text-sm lg:flex flex-1"
        >
          <p
            className="absolute line-clamp-[var(--line-clamp)] md:mt-1"
            style={{ "--line-clamp": lineClamp }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

function Slide({
  slides,
  currentSlideIndex,
  slideData,
  primaryItem,
  secondaryItems,
  carouselPosition,
  isDragging,
  paginate,
}) {
  const router = useRouter();
  const { curriculum } = useUserCurriculumQuery();
  const { logEvent } = useLogEvent({
    event: "Link Clicked",
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });

  const primaryItemEventProperties = {
    title: getOriginalItemTitle(primaryItem),
    sourceElement: `${HERO_UNIT_ITEM_TYPES.PRIMARY} - slide ${carouselPosition}`,
    sourceName: SECTIONS.CAROUSEL,
  };
  const textColor =
    slideData.textTheme === "light" ? "text-white" : "text-gray-900";

  const superNoodleRoute = transformSuperNoodleMarketingUrlToCurriculum(
    primaryItem.content.destinationUrl,
    curriculum,
  );

  const showAgeGate = requiresAgeGate(
    primaryItem.content.destinationUrl,
    curriculum,
  );

  const primaryItemRoute =
    superNoodleRoute ||
    (primaryItem.contentType === CONTENT_TYPES.CUSTOM_LINK
      ? primaryItem.content.destinationUrl
      : `${getRoutePrefixByContentType(primaryItem.contentType)}/${
          primaryItem.content.id
        }/${primaryItem.content.slug}`);

  const partnerLogoInViewRef = useImpression({
    properties: {
      impression_type: IMPRESSION_TYPES.LOGO,
      ad_product_type: SECTIONS.CAROUSEL,
      partner_name: slideData?.partner?.partnerName,
      partner_id: slideData?.partner?.partnerId,
      ad_product_partner_id: slideData?.title,
    },
  });

  return (
    <div
      className={twMerge(
        "grid min-w-full gap-y-6 p-5 md:rounded-lg lg:grid-rows-[auto_1fr_auto] lg:grid-cols-[43.5%_1fr] lg:gap-x-14 lg:px-10",
        secondaryItems.length === 0
          ? "grid-rows-[auto_auto_1fr]"
          : "grid-rows-[auto_auto_auto_1fr]",
      )}
      style={{
        backgroundColor: `#${slideData.backgroundColor}`,
      }}
    >
      <div
        className={twMerge(
          "flex flex-col space-y-1 h-fit lg:order-2",
          textColor,
        )}
      >
        <span className="text-lg font-extrabold line-clamp-2">
          {slideData.title}
        </span>
        <span className="text-sm line-clamp-2">{slideData.description}</span>
      </div>

      <div className="lg:row-span-2 lg:order-1">
        {showAgeGate ? (
          <AgeGatedPressable
            className="block h-fit text-left w-full"
            onPress={() => {
              logEvent(primaryItemEventProperties);

              return router.push(URLS.SUPERNOODLE_URL);
            }}
          >
            <PrimaryCard
              className={twMerge(isDragging && "pointer-events-none")}
              title={primaryItem.title}
              sponsorLogo={primaryItem.sponsorLogo}
              media={{
                loopingVideo: primaryItem.loopingVideo,
                backgroundImage: primaryItem.backgroundImage,
              }}
              callToAction={
                <div className="flex flex-row justify-center items-center text-center">
                  {primaryItem.ctaText}
                  {CONTENT_TYPES.VIDEO === primaryItem.contentType && (
                    <PlayOutlineIcon className="h-input-icon ml-xs shrink-0" />
                  )}

                  {CONTENT_TYPES.ACTIVITY === primaryItem.contentType && (
                    <DownloadOutlineIcon className="h-input-icon ml-xs shrink-0" />
                  )}
                </div>
              }
            />
          </AgeGatedPressable>
        ) : (
          <Link
            href={primaryItemRoute}
            className="block h-fit"
            referrer={{
              sourceElement: HERO_UNIT_ITEM_TYPES.PRIMARY,
              sourceName: SECTIONS.HERO_UNIT,
            }}
            events={[
              {
                event: "Link Clicked",
                properties: {
                  ...primaryItemEventProperties,
                  url:
                    primaryItem.contentType === CONTENT_TYPES.CUSTOM_LINK
                      ? primaryItem.content.destinationUrl
                      : undefined,
                },
              },
            ]}
          >
            <PrimaryCard
              className={twMerge(isDragging && "pointer-events-none")}
              title={primaryItem.title}
              sponsorLogo={primaryItem.sponsorLogo}
              media={{
                loopingVideo: primaryItem.loopingVideo,
                backgroundImage: primaryItem.backgroundImage,
              }}
              callToAction={
                <div className="flex flex-row justify-center items-center text-center">
                  {primaryItem.ctaText}
                  {CONTENT_TYPES.VIDEO === primaryItem.contentType && (
                    <PlayOutlineIcon className="h-input-icon ml-xs shrink-0" />
                  )}

                  {CONTENT_TYPES.ACTIVITY === primaryItem.contentType && (
                    <DownloadOutlineIcon className="h-input-icon ml-xs shrink-0" />
                  )}
                </div>
              }
              impressionsProps={
                primaryItem?.content?.partner?.partnerId
                  ? {
                      ...primaryItemEventProperties,
                      partnerId: primaryItem.content.partner.partnerId,
                      partnerName: primaryItem.content.partner.partnerName,
                    }
                  : null
              }
            />
          </Link>
        )}
      </div>

      <div className="grid gap-y-5 md:grid-cols-2 md:gap-x-5 lg:grid-rows-1 lg:grid-cols-3 lg:order-3">
        {secondaryItems.map((item, itemIndex) => {
          const secondarySuperNoodleRoute = transformSuperNoodleMarketingUrlToCurriculum(
            item.content.destinationUrl,
            curriculum,
          );
          const secondaryShowSuperNoodleRoute = requiresAgeGate(
            item.content.destinationUrl,
            curriculum,
          );

          const secondaryItemRoute =
            secondarySuperNoodleRoute ||
            (item.contentType === CONTENT_TYPES.CUSTOM_LINK
              ? item.content.destinationUrl
              : `${getRoutePrefixByContentType(item.contentType)}/${
                  item.content.id
                }/${item.content.slug}`);

          return secondaryShowSuperNoodleRoute ? (
            <AgeGatedPressable
              key={`${item.content.id}_${itemIndex}`}
              onPress={() => {
                logEvent({
                  title: getOriginalItemTitle(item),
                });

                return router.push(URLS.SUPERNOODLE_URL);
              }}
              className="text-left"
            >
              <SecondaryCard
                title={item.title}
                description={item.description}
                image={item.image}
                textTheme={slideData.textTheme}
                className={twMerge(isDragging && "pointer-events-none")}
                impressionsProps={
                  item?.content?.partner?.partnerId
                    ? {
                        title: getOriginalItemTitle(item),
                        partnerId: item.content.partner.partnerId,
                        partnerName: item.content.partner.partnerName,
                      }
                    : null
                }
              />
            </AgeGatedPressable>
          ) : (
            <Link
              key={`${item.content.id}_${itemIndex}`}
              href={secondaryItemRoute}
              className="block self-stretch"
              referrer={{
                sourceElement: `${HERO_UNIT_ITEM_TYPES.SECONDARY} - slide ${carouselPosition}`,
                sourceName: SECTIONS.CAROUSEL,
              }}
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: getOriginalItemTitle(item),
                    url:
                      item.contentType === CONTENT_TYPES.CUSTOM_LINK
                        ? item.content.destinationUrl
                        : undefined,
                  },
                },
              ]}
            >
              <SecondaryCard
                title={item.title}
                description={item.description}
                image={item.image}
                textTheme={slideData.textTheme}
                className={twMerge(isDragging && "pointer-events-none")}
                impressionsProps={
                  item?.content?.partner?.partnerId
                    ? {
                        title: getOriginalItemTitle(item),
                        partnerId: item.content.partner.partnerId,
                        partnerName: item.content.partner.partnerName,
                      }
                    : null
                }
              />
            </Link>
          );
        })}
      </div>

      <div className="relative self-end h-7 flex flex-col md:flex-row items-center justify-center lg:col-span-2 lg:order-4">
        {slideData.sponsorLogo && (
          <div
            ref={slideData.partner?.partnerId ? partnerLogoInViewRef : null}
            className="flex flex-row items-center gap-x-4 md:mr-auto mb-0"
          >
            <p className={twMerge("font-bold text-xs", textColor)}>
              Powered by
            </p>
            <Image
              className="w-auto h-auto max-w-12 max-h-6 lg:max-w-28 lg:max-h-14 object-fill"
              sources={slideData.sponsorLogo}
              alt=""
            />
          </div>
        )}

        {slides.length > 1 && (
          <div className="flex items-center space-y-4 md:space-y-0 space-x-2 md:absolute">
            <Pressable
              disabled={currentSlideIndex === 0}
              onPress={() => paginate(currentSlideIndex - 1)}
              className="hidden lg:block"
            >
              <ArrowLeftOutlineIcon
                className={twMerge(
                  "w-5 h-5 mr-2 text-gray-600",
                  currentSlideIndex === 0 && "hidden",
                )}
              />
            </Pressable>

            {slides.map((slide, slideIndex) => (
              <Pressable
                key={slideIndex}
                onPress={() => paginate(slideIndex)}
                className={twMerge(
                  "w-[10px] h-[10px] rounded-full",
                  slideIndex === currentSlideIndex
                    ? "bg-purple-500"
                    : "bg-gray-400",
                )}
              />
            ))}

            <Pressable
              disabled={currentSlideIndex === slides.length - 1}
              onPress={() => paginate(currentSlideIndex + 1)}
              className="hidden lg:block"
            >
              <ArrowRightOutlineIcon
                className={twMerge(
                  "ml-2 w-5 h-5 text-gray-600",
                  currentSlideIndex === slides.length - 1 && "hidden",
                )}
              />
            </Pressable>
          </div>
        )}
      </div>
    </div>
  );
}

export default function Carousel({ fetchers }) {
  const [fetcher] = fetchers;
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const [isDragging, setIsDragging] = React.useState(false);
  const { data, isLoading } = useDataLoader(fetcher);
  const [slideRef, slideBounds] = useMeasure();
  const slides = Array.isArray(data) ? data[0] : data;

  const { logEvent: logCarouselClickedEvent } = useLogEvent({
    event: "Carousel Slide Changed",
    properties: {},
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });

  const paginate = (nextSlideIndex) => {
    logCarouselClickedEvent({
      title: `${slides[currentSlideIndex].slideData.title}${nextSlideIndex}`,
      sourceName: `${SECTIONS.CAROUSEL}${nextSlideIndex}`,
      sourceElement: `${SECTIONS.CAROUSEL_NAVIGATION}${nextSlideIndex}`,
    });

    setCurrentSlideIndex(nextSlideIndex);
  };

  if (isLoading) {
    return <Skeleton className="rounded-lg bg-gray-500 h-[465px]" />;
  }

  if (!slides) {
    return null;
  }

  return (
    <div className="relative overflow-hidden -mx-6 md:mx-0">
      <motion.div
        className="flex"
        initial={false}
        ref={slideRef}
        animate={{ x: `-${currentSlideIndex * 100}%` }}
        transition={{ duration: 0.7, ease: [0.32, 0.72, 0, 1] }}
        drag={slides.length > 1 ? "x" : false}
        dragConstraints={{
          left: -slideBounds.width * (slides.length - 1),
          right: 0,
        }}
        dragElastic={1}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);
          if (
            swipe < -swipeConfidenceThreshold &&
            currentSlideIndex + 1 < slides.length
          ) {
            paginate(currentSlideIndex + 1);
          } else if (
            swipe > swipeConfidenceThreshold &&
            currentSlideIndex - 1 > -1
          ) {
            paginate(currentSlideIndex - 1);
          }

          setIsDragging(false);
        }}
      >
        {slides.map(({ slideData, primaryItem, secondaryItems }, index) => {
          const carouselPosition = index + 1;
          return (
            <Slide
              key={index}
              carouselPosition={carouselPosition}
              currentSlideIndex={currentSlideIndex}
              slides={slides}
              slideData={slideData}
              primaryItem={primaryItem}
              secondaryItems={secondaryItems}
              isDragging={isDragging}
              paginate={paginate}
            />
          );
        })}
      </motion.div>
    </div>
  );
}
